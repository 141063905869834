import { Component, Input, SecurityContext, inject, type OnInit } from '@angular/core';
import { type SafeHtml } from '@angular/platform-browser';
import { type Observable } from 'rxjs';
import { SvgIconService } from '../services/svg-icon.service';

@Component({
    selector: 'big-ui-svg-icon',
    templateUrl: './svg-icon.component.html',
    standalone: false,
})
export class SvgIconComponent implements OnInit {
    private readonly svgIcon = inject(SvgIconService);
    public readonly securityContext: SecurityContext = SecurityContext.HTML;

    @Input() public url!: string;

    public iconHtml?: Observable<SafeHtml>;

    public ngOnInit(): void {
        this.iconHtml = this.svgIcon.load(this.url);
    }
}
